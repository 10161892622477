@charset "UTF-8";
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.carousel {
  position: relative;
  background: #FFFFFF;
  text-align: center;
  /**
 * Flexslider variant with thumbnails
 * @author Charles Harwood
 */
}

.carousel .wrapper {
  padding-bottom: 30px;
}

.carousel .slides {
  position: relative;
}

.carousel .slides .slide {
  max-width: 100%;
}

.carousel .slides:after {
  display: block;
  clear: both;
  content: '';
}

.carousel .nav {
  margin-top: 10px;
}

.carousel .nav .nav-items {
  display: inline-block;
  position: relative;
  min-width: 20px;
  height: 32px;
  margin: 0 15px;
  cursor: pointer;
  vertical-align: middle;
}

.carousel .nav .nav-items:before, .carousel .nav .nav-items:after {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 9px;
  background-image: url("../Images/player.png");
  content: '';
}

.carousel .nav .nav-items:before {
  left: -9px;
  background-position: -154px -152px;
}

.carousel .nav .nav-items:after {
  right: -9px;
  background-position: -225px -152px;
}

.carousel .nav .nav-items .sxa-bullets,
.carousel .nav .nav-items .sxa-numbers {
  position: relative;
  width: 20px;
  height: 32px;
  float: left;
}

.carousel .nav .nav-items .sxa-bullets:before {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 32px;
  transform: translate(-50%, -50%);
  background-image: url("../Images/player.png");
  background-position: 0 -152px;
  content: '';
}

.carousel .nav .nav-items .sxa-bullets.active:before {
  background-position: -75px -152px;
}

.carousel .nav .nav-items .sxa-numbers {
  padding-top: 3px;
  background: linear-gradient(to bottom, #eeeeee 0%, #efefef 3%, #fbfbfb 16%, #fefefe 22%, white 34%, #fdfdfd 41%, #f7f7f7 50%, #e8e8e8 66%, #d5d5d5 78%, #cecece 84%, rgba(13, 15, 17, 0.44) 88%, rgba(13, 15, 17, 0.3) 94%, rgba(13, 15, 17, 0.12) 100%);
}

.carousel .nav .nav-items .sxa-numbers:hover {
  color: #878787;
}

.carousel .nav .nav-items .sxa-numbers.active {
  font-weight: bold;
}

.carousel .nav .prev-text,
.carousel .nav .next-text {
  display: inline-block;
  position: relative;
  font-size: 0;
  text-decoration: none;
  vertical-align: middle;
}

.carousel .nav .next-text:hover, .carousel .nav .next-text:active {
  color: #878787;
  text-decoration: none;
}

.carousel .nav .next-text:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  font-size: 20px;
  content: "";
}

.carousel .nav .prev-text:hover, .carousel .nav .prev-text:active {
  text-decoration: none;
}

.carousel .nav .prev-text:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  font-size: 20px;
  content: "";
}

.carousel.image-full-size .carousel-body {
  top: 2%;
  height: auto;
  opacity: 0.8;
}

.carousel.image-full-size .carousel-link {
  right: 1%;
  bottom: 2%;
  left: auto;
  opacity: 0.8;
}

.carousel .flexslider {
  position: relative;
}

.carousel .flexslider .slides .slide {
  width: 100%;
  height: 40vw;
  min-height: 420px;
  max-height: 720px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.carousel .flexslider .slides .slide img {
  display: none;
}

.carousel .flexslider .flex-control-thumbs {
  display: flex;
  position: absolute;
  bottom: 3.8rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.carousel .flexslider .flex-control-thumbs li {
  display: inline-flex;
  position: relative;
  width: 16rem;
  height: 9rem;
  margin: 0 6px;
  cursor: pointer;
}

.carousel .flexslider .flex-control-thumbs li:before {
  display: block;
  position: absolute;
  right: 0;
  bottom: -7px;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
}

.carousel .flexslider .flex-control-thumbs li.flex-active:before {
  background-color: #ee2737;
}

.carousel .flexslider .flex-control-thumbs li img {
  min-width: 100%;
  min-height: 100%;
}

@media (max-width: 991px) {
  .carousel .flexslider .flex-control-thumbs li {
    width: 10rem;
    height: 5.625rem;
  }
}

@media (max-width: 767px) {
  .carousel .flexslider .flex-control-thumbs {
    display: none;
  }
}

@media (max-width: 768px) {
  .carousel {
    padding: 0;
  }
}
